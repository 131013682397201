import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";

const Funds = ({
  children,
  transferCryptoData,
  pageNo,
  setPageNo
}) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <button className={styles.link}>
          <span>Convert Crypto Listing</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Currency</div>
          <div className={styles.col}>Coin</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date</div>
        </div>
        {
          transferCryptoData?.length > 0 ?
            <>
              {transferCryptoData?.map((x, index) => (
                <Item
                  className={styles.item}
                  item={x}
                  key={index}
                  children={children}
                />
              ))}
              {transferCryptoData?.length === (10 * pageNo) && <div className={styles.customButton}>
                <button className={cn("button-stroke button-small", styles.button)} onClick={() => setPageNo(pageNo + 1)} >
                  <span>Load more...</span>
                </button>
              </div>
              }
            </>
            :
            <div className={styles.btns}>
              <NoDataFound />
            </div>
        }
      </div>
    </div>
  );
};

export default Funds;
